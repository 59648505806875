var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notifications',{attrs:{"group":"notification","position":"bottom right"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"card card-custom card-sticky",attrs:{"id":"kt_page_sticky_card"}},[_c('div',{staticClass:"card-header"},[_vm._m(0),_c('CardToolbar',{attrs:{"back-text":"Back to list","back-url":"/products-attributes/list","button-text":"Create Attribute"},on:{"submitEvent":_vm.submit}})],1),_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"attributeTitle"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attribute.title),expression:"attribute.title"}],staticClass:"form-control form-control-solid",attrs:{"id":"attributeTitle","placeholder":"Enter Attribute title"},domProps:{"value":(_vm.attribute.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attribute, "title", $event.target.value)}}})])])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"card card-custom"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Parent")]),_c('v-select',{staticClass:"form-control form-control--category-select",attrs:{"placeholder":"Select parent attribute","options":_vm.filteredAttributes,"reduce":function (attribute) { return attribute['@id']; },"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var parent = ref.parent;
var productCategory = ref.productCategory;
return [(parent)?_c('span',[_vm._v("-- "+_vm._s(title))]):_c('span',[_c('span',{staticClass:"label label-lg label-light-primary label-inline"},[_vm._v(_vm._s(productCategory.title))]),_vm._v(" "+_vm._s(title))])]}}]),model:{value:(_vm.attribute.parent),callback:function ($$v) {_vm.$set(_vm.attribute, "parent", $$v)},expression:"attribute.parent"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Related to")]),_c('v-select',{staticClass:"form-control form-control--category-select",attrs:{"placeholder":"Select category","options":_vm.filteredCategories,"reduce":function (category) { return category['@id']; },"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var parent = ref.parent;
return [(parent)?_c('span',[_vm._v("-- "+_vm._s(title))]):_c('span',[_vm._v(_vm._s(title))])]}}]),model:{value:(_vm.attribute.productCategory),callback:function ($$v) {_vm.$set(_vm.attribute, "productCategory", $$v)},expression:"attribute.productCategory"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Is group")]),_c('MCSwitch',{attrs:{"classes":"switch-icon float-right","name":"isPublish","is-checked":_vm.attribute.isGroup},on:{"checkedEvent":_vm.toggleIsGroup}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Position")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.attribute.position),expression:"attribute.position",modifiers:{"number":true}}],staticClass:"form-control form-control-sm form-control-solid",attrs:{"type":"number"},domProps:{"value":(_vm.attribute.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attribute, "position", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v("Add New Attribute")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v("Options")])])])}]

export { render, staticRenderFns }